.city-parent-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
  position: relative;
}

.city-div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f1f1f1;
  border-radius: 6px;
  height: 47px;
}

.city-field {
  left: 60px;
  top: 0px;
  border-radius: 4px;
  outline: none;
  border: none;
  padding-bottom: 1px;
  border-radius: 4px;
  padding: 0px 0px 1px 10px;
  -webkit-appearance: none;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  /* height: 45px;
    width: 298px; */
  background-color: transparent !important;
}

.city-field-seleted {
  color: #75808b;
}

.city-field-font {
  color: #ffff;
}

.inputModeBorder {
  border: 1px solid #626262;
}

.cityFieldBackground {
  background: #000000 !important;
}
