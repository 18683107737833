.ridedetailrow {
  width: 100%;
  position: relative;
}

.typeSection {
  width: 89%;
  position: absolute;
  background-color: white;
  /* border-radius: 10px 10px 0px 0px;  */
  padding: 20px 5.5% 15px 5.5%;
}

.typeSection .tophead {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: flex-start;
}

.typeSection .tophead_div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.3em 0em;
  gap: 1em;
}

.typeSection .tophead h4 {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  padding-top: 10px;
}

.cityBorder {
  width: 76%;
  display: flex;
  justify-content: right;
  align-items: center;
  border: solid 1px #01b15c;
  border-radius: 4px;
}

.inputDarkModeBackground {
  background: #292929;
  color: #ffff;
}

.inputModeBackground {
  background: #f1f1f1;
}

#locationPinDiv {
  display: flex;
  justify-content: right;
  align-items: center;
  width: 13%;
  padding-right: 5px;
}

.typeSection .stateselect {
  height: 35px;
  /* width: 75%; */
  width: 100%;
  left: 60px;
  top: 0px;
  border-radius: 4px;
  outline: none;
  /* border: solid 1px #01B15C; */
  border: none;
  font-weight: bold;
  font-size: 14px;
  padding-bottom: 1px;
}

.typeSection .tophead .arealist .areaselect {
  height: 34px;
  width: 98%;
  left: 60px;
  top: 0px;
  border-radius: 4px;
  outline: none;
  /* border: solid 1px #01B15C; */
  border: none;
  font-weight: bold;
}

.typeSection .tophead .arealist {
  width: 75%;
  display: flex;
  /* flex-direction: column; */
  position: relative;
  border: solid 1px #01b15c;
  border-radius: 4px;
  padding: 1px;
}

.typeSection .tophead .arealist ul {
  width: 89%;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  font-size: 3.8vw;
  cursor: pointer;
  position: absolute;
  top: 38px;
  padding: 0px 14px 0;
  overflow: auto;
  max-height: 180px;
}

.darkModeUL {
  background: #292929;
  color: #fff;
}

.lightModeUL {
  background: whitesmoke;
}

.typeSection .tophead .arealist ul li {
  cursor: pointer;
  border-bottom: 1px dashed #eee;
  line-height: 30px;
  display: flex;
  align-items: center;
}
.tagline {
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #21ac4b;
  padding-bottom: 2em;
}
/* dark mode design for ridetype */
.ridetype-dark {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ridetype-dark .ridetypeinput {
  display: none;
}
.ridetype-dark .ridetypelbl {
  color: white;
  padding: 0.31em 0em;
  transition: 200ms all ease-in-out;
  cursor: pointer;
  border: 1px solid green;
  font-weight: 700;
  display: inline-block;
  width: 110px;
  text-align: center;
}
.ridetype-dark div:first-child label {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.ridetype-dark div:last-child label {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.ridetype-dark .ridetypeinput:checked + .ridetypelbl {
  background: green;
  color: white;
}
/* light mode for ride type */

.ridetype-light {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ridetype-light .ridetypeinput {
  display: none;
}
.ridetype-light .ridetypelbl {
  color: #000000;
  padding: 0.31em 0em;
  transition: 200ms all ease-in-out;
  cursor: pointer;
  border: 1px solid green;
  font-weight: 700;
  display: inline-block;
  width: 110px;
  text-align: center;
}
.ridetype-light div:first-child label {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.ridetype-light div:last-child label {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.ridetype-light .ridetypeinput:checked + .ridetypelbl {
  background: green;
  color: white;
}

/* dark mode style for hours section */
.hours-dark {
  display: flex;
  justify-content: center;
  gap: 1.3em;
  flex-direction: column;
  align-items: center;
  padding: 1.3em 0em;
}

.hours-dark p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #75808b;
  width: 104px;
  height: 17px;
}

.hours-dark .hours-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.2em;
}
.hours-dark .hoursinput {
  display: none;
  position: relative;
}
.hours-dark .hourslbl {
  color: white;
  transition: 200ms all ease-in-out;
  cursor: pointer;
  border: 1px solid #20ac4b;
  font-weight: 700;
  border-radius: 50%;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
}

.hours-dark .hoursinput:checked + .hourslbl {
  background: green;
  color: white;
}

/* light mode for hours */

.hours-light {
  display: flex;
  justify-content: center;
  gap: 1.3em;
  flex-direction: column;
  align-items: center;
  padding: 1.3em 0em;
}

.hours-light p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #75808b;
  width: 104px;
  height: 17px;
}

.hours-light .hours-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.2em;
}
.hours-light .hoursinput {
  display: none;
  position: relative;
}
.hours-light .hourslbl {
  color: #000000;
  transition: 200ms all ease-in-out;
  cursor: pointer;
  border: 1px solid #20ac4b;
  font-weight: 700;
  border-radius: 50%;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
}

.hours-light .hoursinput:checked + .hourslbl {
  background: green;
  color: white;
}

/* dark mode for ride details container */
.ride-details-container-dark {
  padding: 1.9em 1em;
  border: 1px solid #626262;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.ride-details-container-dark .ride-details-controls {
  border: 1px solid #f1f1f1;
  border-radius: 6px;
  outline: none;
  background-color: #000000;
  color: #75808b;
  padding: 1em;
}
.ride-details-container-dark .ride-details-controls {
  background-color: transparent;
  color: white;
  border-radius: 6px;
  outline: none;
  padding: 1em;
}
.ride-details-container-dark .ride-details-controls::placeholder {
  font-style: normal;
  font-weight: bolder;
  font-size: 14px;
  line-height: 17px;
  color: #75808b;
}

/* light mode for ride-details-container */

.ride-details-container-light {
  padding: 1.9em 1em;
  border: 1px solid #f1f1f1;
  display: flex;
  flex-direction: column;
  gap: 1em;
  border-radius: 4px;
}
.ride-details-container-light .ride-details-controls {
  border: 1px solid #667079;
  border-radius: 6px;
  outline: none;
  background-color: #000000;
  color: white;
  padding: 1em;
}
.ride-details-container-light .ride-details-controls {
  background-color: transparent;
  color: black;
  border-radius: 6px;
  outline: none;
  padding: 1em;
  font-weight: 700;
}
.ride-details-container-light .ride-details-controls::placeholder {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #75808b;
}

.ride-details-container-light .ride-details-controls::placeholder {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #666666;
}

.triptypeinput {
  display: none;
  position: relative;
}

/* dark mode for the trip-type */
.trip-type-dark {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  padding: 1.3em 0em;
}

.trip-type-dark div {
  flex: 1 0 150px;
}

.trip-type-dark div label {
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  background-color: #009e52;
  padding: 0.3em 0.5em;
  background: #1d1d1d;
  border-radius: 4px;
  transition: 200ms all ease-in-out;
}
.trip-type-dark div p:nth-child(1) {
  font-size: 12px;
  font-weight: 700;
}
.trip-type-dark div p:nth-child(2) {
  font-weight: 400;
  font-size: 9px;
  color: #75808b;
}
.trip-type-dark .triptypeinput:checked + .triptypelbl {
  background: green;
  color: white;
}
.trip-type-dark .triptypeinput:checked + .triptypelbl p:nth-child(2) {
  color: white;
}

/* light mode for trip-type  */

.trip-type-light {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  padding: 1.3em 0em;
}

.trip-type-light div {
  flex: 1 0 150px;
}

.trip-type-light div label {
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  background-color: #009e52;
  padding: 0.3em 0.5em;
  background: #f1f1f1;
  border-radius: 4px;
  transition: 200ms all ease-in-out;
}
.trip-type-light div p:nth-child(1) {
  font-size: 12px;
  font-weight: 700;
}
.trip-type-light div p:nth-child(2) {
  font-weight: 400;
  font-size: 9px;
  color: #75808b;
}
.trip-type-light .triptypeinput:checked + .triptypelbl {
  background: green;
  color: white;
}
.trip-type-light .triptypeinput:checked + .triptypelbl p:nth-child(2) {
  color: white;
}

.auto-complete-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
  position: relative;
}
.autocomplete {
  width: 100%;
}
.auto-complete-container .autocomplete input {
  position: relative;
  width: 100%;
  font-weight: 700;
}

.auto-complete-container .autocomplete input::placeholder {
  color: #75808b;
  font-weight: 600;
  font-size: 14px;
}

.darkModeRadio {
  -webkit-appearance: none;
  margin-right: 10px;
  width: 22px;
  height: 22px;
  accent-color: #009e52;
  border: 1px solid #ffff;
  border-radius: 50%;
  outline: none;
}

.darkModeRadio:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

.darkModeRadio:checked:before {
  background: #009e52;
}

.typeSection .ridetype h4 {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
}

.typeSection .locationType {
  width: 100%;
  margin-top: 20px;
}

.typeSection .locationType .locationTyperow {
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.typeSection .locationType .locationTyperow img {
  margin-right: 7px;
}

.typeSection .locationType .locationTyperow .locationInput {
  width: 96%;
  border: none;
  border-bottom: solid 1px #f1f1f1;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  padding: 5px 0;
  outline: none;
}

.darkModelocationInput {
  width: 100%;
  border: none;
  border-bottom: solid 1px #f1f1f1;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  padding: 5px 0;
  outline: none;
  background: #000;
  color: #fff;
  font-weight: 300;
}

.darkModelocationInput::placeholder {
  color: #fff;
}

.typeSection .locationType .locationTyperow .locationInput::placeholder {
  color: rgb(21, 21, 21);
}

.carType {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.carType h4 {
  margin-right: 10px;
}

.carType .carselectbtn {
  margin: 0 10px;
  height: 22px;
  width: 22px;
  accent-color: #009e52;
}

.seatType {
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: space-between;
}

.seatType h4 {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  margin-right: 10px;
}

.seatType button {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: none;
  color: #bdbdbd;
  margin: 0 10px;
}

.seatType .babycheck {
  border: solid 1px #bdbdbd;
  height: 18px;
  width: 18px;
}

.checkboxrow {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.messagerow {
  width: 100%;
  margin-top: 20px;
}

.messagerow input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #667079;
  font-size: 14px;
  padding-left: 2%;
  padding: 0% 0% 1% 2%;
  outline: none;
}

.messagerow .messagecol {
  width: 100%;
  display: flex;
  align-items: center;
}

.messagerow .messagecol img {
  margin-right: 10px;
  height: 15px;
  width: 23px;
}

.messagerow textarea {
  margin-top: 10px;
  width: 98%;
  border: solid 1px #f1f1f1;
}

.location {
  position: relative;
}
.location input {
  width: 91%;
}

.findcabBtn {
  height: 44px;
  width: 100%;
  color: #f1f1f1;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  background: #21ac4b;
  border: 1px solid #000000;
  box-shadow: 4px 4px 0px #000000;
  border-radius: 4px;
}

.findcabBtn :disabled,
.findcabBtn[disabled] {
  background: #8fdbb6 !important;
}

.footbtn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

.pac-container {
  z-index: 9999;
}

.calendarBox {
  height: 294px;
  width: 95%;
  border-radius: 4px;
  background-color: #353535;
  margin: 25px auto 0 auto;
}

.timeBox {
  height: 40px;
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  /* background-color: #353535; */
  padding: 1px;
}

.timeBox div {
  width: 33%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.timeBox div select {
  height: 30px;
  color: #000;
  border: none;
  outline: none;
  text-align: center;
  font-size: 16px;
  color: #21ac4b;
}

.dateinput {
  width: 99%;
  height: 30px;
  border: none;
  border-bottom: solid 1px #f1f1f1;
  outline: none;
  font-size: 16px;
  caret-color: transparent;
  color: #21ac4b;
}

.areaValue {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 2;
}

.back_button_div {
  display: flex;
  justify-content: left;
  align-items: center;
}

.back_button_div button {
  border: none;
  background-color: transparent;
}

#header_line_div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.header_line {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.5em;
  font-size: 16px;
  font-weight: bold;
}

#header_icon {
  padding: 1px 0px 0px 10px;
}

.darkModeBackground {
  background: #000000;
  color: #ffff;
}

.darkModeDateTime {
  background: #000000;
  color: #21ac4b;
}

#header_sub_text {
  font-size: 7px;
  padding-bottom: 10px;
  padding: 0px 0px 10px 2px;
}

body.dark {
  background-color: #000000;
}
.airport_tophead {
  width: 50%;
}
.dropdownDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px 0px 5px;
}

.dropdownIcon {
  height: 8.17px;
  width: 14.21px;
}
.airport_pickupDropBorder {
  display: flex;
  border-radius: 4px;
  border: solid 1px #01b15c;
  padding: 1px;
}

.airport_cityBorder {
  display: flex;
  border-radius: 4px;
  /* border: solid 1px #01B15C; */
  padding: 1px;
}

.airport_city_active_border {
  border: solid 1px #01b15c;
}

.airport_pickupselect {
  width: 100%;
  height: 35px;
  left: 60px;
  top: 0px;
  border-radius: 4px;
  outline: none;
  border: none;
  font-weight: 600;
  font-size: 14px;
  padding: 0px 0px 1px 10px;
  -webkit-appearance: none;
}

.airport_pickupselect option {
  width: 100%;
}

.airport_stateselect {
  height: 35px;
  left: 60px;
  top: 0px;
  border-radius: 4px;
  outline: none;
  border: none;
  font-weight: 600;
  font-size: 14px;
  padding-bottom: 1px;
  border-radius: 4px;
  padding: 0px 0px 1px 10px;
  -webkit-appearance: none;
  width: 100%;
}
.airport_stateselect option {
  width: 10px;
}
.airport_stateselect::-webkit-scrollbar {
  width: 5px;
}
.airport_stateselect::-webkit-scrollbar-thumb {
  background-color: #20ac4b; /* Color of the thumb */
  border-radius: 5px; /* Rounded corners */
}

.dropdownDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px 0px 5px;
}

.dropdownIcon {
  height: 8.17px;
  width: 14.21px;
}

.hour-underline-text {
  height: 10px;
  width: 43px;
  left: 86px;
  top: 227px;
  font-size: 8px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
}

.selcted-underline-text {
  color: #009e52;
}
