.opretorList {
  width: 100%;
}

.opretorhead {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  padding-top: 2px;
}

.opretorhead h2 {
  font-family: Manrope;
  font-size: 20px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  padding-right: 10px;
}

.opretorhead p {
  width: 69px;
  height: 18px;
  left: 274px;
  top: 135px;
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 18px;
  color: #00a3ff;
  text-align: center;
  padding-right: 5px;
}

.opretorhead .opretorLogo {
  width: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.opretorhead .opretorLogo span {
  margin-right: 10px;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
}

.opretorhead .opretorLogo img {
  width: 32px;
  height: 32px;
}

.driverDetails {
  width: 100%;
  height: 28px;
  display: flex;
  justify-content: left;
  align-items: center;
}

.driverDetails .driverImg {
  width: 15%;
  margin-right: 10px;
}

.driverDetails .driverImg img {
  width: 100%;
  margin-top: 8px;
}

.driverDetails .driverName {
  width: 100%;
}

.driverDetails .driverName h5 {
  font-size: 16px;
  font-weight: 500;
}

.driverDetails .driverName h6 {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #75808b;
  font-style: normal;
}

.bookOperator {
  height: 44px;
  width: 100%;
  border-radius: 20px;
  border: none;
  /* background: linear-gradient(312.03deg, #009E52 5.14%, #00CC6A 100%); */
  color: #fff;
  margin-top: 10px;
  margin-bottom: 5px;
  background: #21ac4b;
  border: 1px solid #000000;
  box-shadow: 4px 4px 0px #000000;
  border-radius: 4px;
  font-weight: 500;
}

.darkModeFindcabBtn {
  border: 1px solid #fff;
}

.vendorLink {
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tclink {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #21ac4b;
  padding-left: 10px;
}

.operator_card {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px 10px 10px;
  margin: 10px 0px 10px 0px;
  border-radius: 5px;
}

.light_mode_background {
  background-color: #ffff;
}

.dark_mode_border {
  border: 1px solid #626262;
}

.tandc_parent_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tandc_parent_div {
  width: 100%;
  margin-top: 5%;
}

#opretor_img {
  height: 60px;
  width: 60px;
  padding-left: 28%;
  object-fit: contain;
}
