.navbar{
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 9999;
    padding: 6px 0;
    background-color: white;
}

.navbar nav{
    width: 95%;
    /* box-shadow: 0 1px 3px rgb(0 0 0 / 6%), 0 1px 4px rgb(0 0 0 / 16%); */
    margin: 0 auto;
    display: flex;

}

.leftDiv{
    width: 20%;
}

.centerDiv{
    width: 70%;
}

.backIcon{
    color: black;
    font-size: 18px !important;
}

.navbar nav h6{
    color: black;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    font-weight: bold;
}