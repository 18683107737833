.bookingpage{
    width:100%;
    padding: 0px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 94vh;
    position: relative;
}

.bookingpage h2{
    font-size: 20px;
    font-weight: 400;
    line-height: 18px;
    color: #fff;
    margin: 30px 0;
}

.innerBox{
    width:92%;
    padding: 15px 4% 10px 4%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 20px 20px 0 0;
    position: fixed;
    bottom: 0;
    background-color: white;
    z-index: 9999;
    box-shadow: 0px 2px 20px 0px #00000047;
}


.innerBox select{
    width: 100%;
    background-color: transparent;
    outline: none;
    border: none;
    border-bottom: rgb(120, 120, 120) solid 1px;
    padding: 20px 0; 
    color: #75808B;
}

.innerBox select:focus{
    color: #75808B;
}

.bookrideBtn{
    height: 44px;
    width: 98%;
    border-radius: 20px;
    background: linear-gradient(312.03deg, #009E52 5.14%, #00CC6A 100%);
    color: #fff;
    font-size: 14px;
    border: none;
    margin-top: 10px;
}

.booklaterBtn{
    height: 44px;
    width: 98%;
    border-radius: 20px;
    background: linear-gradient(312.03deg, #009E52 5.14%, #00CC6A 100%);
    color: #ffffff;
    font-size: 14px;
    border: none;
    margin-top: 10px;
    opacity: 0.2;
}

.innerBox .locationTofrom{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}

.innerBox .locationTofrom img{
    width: 8%;
}

.innerBox .locationTofrom .inputTo{    
    width: 96%;
    background-color: transparent;
    outline: none;
    border: none;
    border-bottom: #F1F1F1 solid 1px;
    padding: 5px 0; 
    color: #75808B;
}

.inputwidth{
    width: 90%;
}

.pac-container{
    z-index: 9999;
}

