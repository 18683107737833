.scheduledDetails {
  width: 100%;
  padding: 5% 0% 16% 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
}

.darkMode {
  background: #23232c;
  color: #ffff;
  height: 88vh;
}

.scheduledDetails img {
  width: 50%;
  margin-bottom: 50px;
}

#message_div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  margin-top: 78%;
}

.scheduledHead {
  width: 80%;
  display: flex;
  justify-content: center;
  margin-top: 8%;
  flex-wrap: wrap;
  line-height: 0;
  flex-direction: column;
  align-items: center;
}

.scheduledHead h2 span {
  /* font-family: Manrope; */
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-align: center;
}

.success_h2 {
  color: #01ad5a;
}

.failed_h2 {
  color: #e32137;
}

.userProfile {
  width: 89%;
  padding: 0 5.5%;
  display: flex;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
}

.userProfile .avatar-col {
  width: 10%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userProfile .avatar-col img {
  width: 100%;
}

.userProfile .avatar-details {
  width: 90%;
}

.userProfile .avatar-details h4 {
  font-size: 15px;
}

.userProfile .avatar-details h4 span {
  font-size: small;
  color: rgb(165, 165, 165);
}

.userProfile .avatar-details h5 {
  font-size: small;
  color: rgb(165, 165, 165);
  font-weight: lighter;
}

.from-row {
  width: 100%;
  margin-top: 14px;
}

.from-row h2 {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}

.from-row h5 {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #75808b;
  text-align: center;
}

.from-row h5 span {
  color: #009e52;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.paymentOption {
  width: 89%;
  padding: 0 5.5%;
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.paymentOption .paymentOptionBtn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.paymentOption .paymentOptionBtn button {
  width: 47%;
  border: solid 1px #cfcfcf;
  border-radius: 5px;
  background: none;
  height: 40px;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  color: #cfcfcf;
}

.paymentOption button img {
  margin-right: 5px;
}

.paymentOption p {
  font-family: Manrope;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  margin-top: 10px;
}

.tryagain_button_div {
  width: 89%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 5.5%;
  margin-top: 115%;
  position: absolute;
}

.tryagain_button_div button {
  height: 40px;
  width: 100%;
  color: #ffff;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  background: #21ac4b;
  border: 1px solid #000000;
  box-shadow: 4px 4px 0px #000000;
  border-radius: 4px;
}

.darkModeScheduleRideDoneFooter button {
  border: 1px solid #ffff;
}

.ScheduleRideDoneFooter {
  width: 89%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 5.5%;
  margin-top: 64%;
  height: 100px;
}

.ScheduleRideDoneFooter button {
  height: 40px;
  width: 100%;
  color: #ffff;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  background: #21ac4b;
  border: 1px solid #000000;
  box-shadow: 4px 4px 0px #000000;
  border-radius: 4px;
}

.darkModeScheduleRideDoneFooter button {
  border: 1px solid #ffff;
}

.ScheduleRideDoneFooter h3 {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;
  color: #ff4a4a;
  margin: 24px 0;
}

.ScheduleRideDoneFooter div {
  position: absolute;
}

.ScheduleRideDoneFooter p {
  font-family: Manrope;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  padding: 0 45px;
}

.ScheduleRideDoneFooter a {
  font-family: Manrope;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  color: #75808b;
  margin-top: 24%;
  position: absolute;
}

.companylogo {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.companylogo img {
  width: 30%;
}

#bookingConfirmedImg {
  height: 154px;
  width: 154px;
  margin: 25% 0% 0% 0%;
}

.done_back_button_div {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  border: none;
  background: none;
  padding: 17px 0px 0px 8px;
  display: flex;
  justify-content: left;
  width: 100%;
}

.done_back_button_div img {
  height: 18px;
  width: 11px;
  margin: 0;
}
