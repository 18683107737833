.payed{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    /* text-align: center; */
}

.payed img{
    width: 100%;
}

/* .from-row{
    width: 89%;
    padding: 0 5.5%;
    margin-top: 20px;
}

.from-row h2{
    font-family: Manrope;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
}

.from-row h5{
    font-family: Manrope;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    color: #75808B;
} */
