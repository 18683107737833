.listDetails {
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: #F5F5F5;
    /* border-radius: 10px 10px 0px 0px; */
    height: 100vh;
    padding: 10px;
    box-sizing: border-box;
    overflow-y: auto;
}

.listDetails .locationDetails{
    width: 100%;
    height: 70vh;
}

.listDetails .locationDetails .rideDetails {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 0px 25px 0px;
}

.darkModeBackground {
    background: #000000;
    color: #ffff;
}

.locationDetails .fromDetails{
    width: 100%;
    margin: 10px 0 10px 8px;
}

.locationDetails .fromDetails div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.locationDetails .fromDetails img {
    padding-left: 15%;
}

.locationDetails .fromDetails h3 {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
}

.locationDetails .fromDetails p{
    font-family: Manrope;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: #75808B;
}

.rideDetails .rideDetails_left h3 {
    font-family: Manrope;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    padding-top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rideDetails button {
    border: none;
    background: none;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 0;
    margin-right: 10%;
}

.rideDetails .rideDetails_left p{
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #75808B;
}

.rideDetails .rideDetails_right{
    color: #009E52;
}

.rideforsurePrice {
    font-size: 20px;
    font-weight: 1000;
    color: #000000;
    padding-right: 10px;
}

.darkRideforsureText {
    color: #ffff;
}

#mand_message {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    color: #75808B;
    /* padding: 10px; */
    margin: 10% 0% 10% 0%;
}

.scheduleCabButton {
    height: 44px;
    width: 100%;
    color: #fff;
    margin-bottom: 5px;
    /* background: linear-gradient(312.03deg, #009E52 5.14%, #00CC6A 100%); */
    background: #21AC4B;
    border: 1px solid #000000;
    box-shadow: 4px 4px 0px #000000;
    border-radius: 4px;
    font-weight: 500;
}

.darkModeBtn {
    border: 1px solid #fff;
}

#rideforsure_sub_text {
    font-size: 9px;
    font-weight: 700;
    padding-bottom: 10px;
    padding: 0px 4px 10px 2px;
}

.loaderImgDiv {
    height: 97%;
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: rgba(245,245,245,0.8);
    z-index: 1;
    flex-direction: column;
    text-align: center;
    font-size: 12px;
}

.loaderBackgroundDark {
    background-color: rgba(0,0,0,0.8);
}

#bottom_div {
    width: 100%;
}