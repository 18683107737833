
.mbsc-ios-dark.mbsc-textfield-wrapper {
    width: 90% !important;
}

.mbsc-ios-dark.mbsc-textfield-box, .mbsc-ios-dark.mbsc-textfield-outline {
    background: #fff !important;
}

.mbsc-ios-dark.mbsc-label, .mbsc-ios-dark.mbsc-textfield {
    color: #000  !important;
    background-color: #fff !important;
}

.mbsc-ios.mbsc-textfield-wrapper-box, .mbsc-ios.mbsc-textfield-wrapper-outline {
    margin: 0 !important;
}

.mbsc-ios.mbsc-textfield-floating, .mbsc-ios.mbsc-textfield-stacked {
    height: 2.5em !important;
}

.mbsc-ios-dark.mbsc-button-flat {
    background-color: #009E52;
    color: #fff !important;
}

.mbsc-ios-dark.mbsc-popup-buttons{
    border: none !important;
}

.mbsc-ios-dark.mbsc-popup-buttons-anchored, .mbsc-ios-dark.mbsc-popup-buttons-bottom, .mbsc-ios-dark.mbsc-popup-buttons-top {
    background: #fff !important;
}


.mbsc-ios-dark.mbsc-scroller, .mbsc-ios-dark.mbsc-scroller-bottom, .mbsc-ios-dark.mbsc-scroller-top{
    /* background: linear-gradient(312.03deg, #009E52 5.14%, #00CC6A 100%) !important; */
    background: #D0D3DA !important;
}

.mbsc-ios.mbsc-popup-bottom {
    border-top: #D0D3DA solid 1px !important;
}
.mbsc-ios-dark.mbsc-scroller-wheel-overlay, .mbsc-ios-dark.mbsc-scroller-wheel-overlay-bottom, .mbsc-ios-dark.mbsc-scroller-wheel-overlay-top{
    background: transparent !important;
}

.mbsc-ios-dark.mbsc-scroller-pointer.mbsc-scroller, .mbsc-ios-dark.mbsc-scroller-pointer.mbsc-scroller-inline, .mbsc-ios-dark.mbsc-scroller-wheel-cont-bottom.mbsc-scroller-wheel-cont-3d, .mbsc-ios-dark.mbsc-scroller-wheel-cont-inline.mbsc-scroller-wheel-cont-3d, .mbsc-ios-dark.mbsc-scroller-wheel-cont-top.mbsc-scroller-wheel-cont-3d{
    background: rgb(255, 248, 248) !important;
}

.mbsc-ios-dark.mbsc-scroller-wheel-item-2d, .mbsc-ios-dark.mbsc-scroller-wheel-item.mbsc-selected {
    color: #000 !important;
}

.css-b62m3t-container {
    width: 75% !important;
}

.css-1s2u09g-control{
    border-color: #01B15C !important;
    font-size: 13px !important;
    color: #000 !important;
}

.css-tlfecz-indicatorContainer{
    color: #000 !important;
}