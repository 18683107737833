.organizationList {
  width: 100%;
  position: relative;
}

.organizationList .map {
  width: 100%;
}

.organizationList .map img {
  width: 100%;
  object-fit: cover;
}

#back_button_div {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  border: none;
  background: none;
  padding: 17px 0px 0px 8px;
}

.listDetails {
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #f5f5f5;
  /* border-radius: 10px 10px 0px 0px; */
  height: 100vh;
  /* padding: 20px; */
  padding: 10px;
  box-sizing: border-box;
  overflow-y: auto;
}

.listDetails .locationDetails {
  width: 100%;
  position: relative;
}

.listDetails .locationDetails .rideDetails {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0px 25px 0px;
}

.rideDetails .rideDetails_right {
  color: #009e52;
}

.rideDetails .rideDetails_right button {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #009e52;
  border: none;
  background: none;
  width: 101px;
}

.locationDetails .fromDetails {
  width: 100%;
  margin: 10px 0 10px 8px;
}

.locationDetails .fromDetails h3 {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.locationDetails .fromDetails p {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #75808b;
}

.availbleCabs {
  width: 100%;
  margin-top: 30px;
}

.overLay {
  display: none;
}

.overLay.show {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
}
.availbleCabs h2 {
  font-family: Manrope;
  font-size: 20px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
}

.loader-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 11px;
  text-transform: capitalize;
  text-align: center;
  line-height: 18.5px;
}

.loader-parent-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
}

.loaderDarkModeBackground {
  background-color: #000000;
  height: 100vh;
  color: #ffff;
}

.loaderLightModeBackground {
  height: 100vh;
}

.darkModeBackground {
  background: #000000;
  color: #ffff;
}
